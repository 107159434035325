import React from 'react';
import { LocaleTypes } from '../data/locales';
import { 
  DetektaHero,
  DetektaBenefits,
  DetektaTrafficLight,
  DetektaAdvantage,
  DetektaCTA
} from '../components/detekta'
import IndexLayout from '../layouts';
import Footer from '../components/Footer';
import SEO from '../components/SEO';

import '../styles/styles.scss'

interface DetektaProps {
  pathContext: {
    localeCode: LocaleTypes;
  };
  location: Location
}

const detekta: React.FC<DetektaProps> = ({ pathContext, location }) => {

  const content = (
    <>
      <SEO title="Detekta" description="Detekta" />

      <div className="ingenio-bg-hero-detekta">
        <DetektaHero />
      </div>

      <DetektaBenefits />

      <DetektaTrafficLight />

      <DetektaAdvantage />

      <DetektaCTA />

      <div className="ingenio-bg-footer">
        <Footer />
      </div>
    </>
  )

  return (
    <IndexLayout location={location} content={content}  />
  )
}

export default detekta