import React, {FC} from 'react'
import { Link } from 'gatsby'
import { Grid, Typography, Container, Button } from '@mui/material';
import './styles.scss'

const DetektaCTA: FC = () => {
  return (
    <div className="detekta-cta">
      <Container>
        <Grid container lg={12} md={12} sm={12}
          spacing={{ xs: 2, md: 3 }}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          className="detekta-cta-grid"
        >
          <Typography align="center" variant="h5" gutterBottom color="text.alternate" sx={{ mt: 5 }}>
            Reduce los riesgos al adquirir un inmueble,
            nuestro servicio es rápido y profesional. 
          </Typography>
          <Link to='/contacto'>
            <Button variant="contained" sx={{ my: { sm: 3 } }} color="primary" className="ing-index-hero__button">Contáctanos</Button>
          </Link>
        </Grid>
      </Container>
    </div>
  )
}

export default DetektaCTA