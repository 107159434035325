import React, {FC} from 'react'
import { Grid, Typography, Container } from '@mui/material';
import ideal from './assets/images/ideal.svg';
import alert from './assets/images/alerta.svg';
import aceptable from './assets/images/aceptable.svg';
import './styles.scss';

const DetektaTrafficLight: FC = () => {
  return (
    <div className="detekta-stoplight-container">
      <Container sx={{ py: 5 }} >
        <Typography variant="h1" color="text.dark" align="center" >
          Semáforo de estado jurídico
        </Typography>
        <Grid container sx={{ mt: 5 }}
          direction="row"
          justifyContent="center"
          alignItems="start"
          columns={{ xs: 4, sm: 10, md: 12 }}>
          <Grid item
          sx={{ display: 'flex', flexDirection:'column',
          justifyContent:'center',
          alignItems: 'center', my: { sm: 4 } }}
          xs={12} md={4} lg={4} >
          <img src={ideal} alt="ideal" />
          <Typography align="center" variant="body2" gutterBottom color="text.dark" sx={{ mt: 5 }}>
            Predio <strong>sin condicionantes,</strong> limitaciones, medidas catelares, gravámenes o hallazgos jurídicos.
          </Typography>
          </Grid>
          <Grid sx={{ display: 'flex', flexDirection:'column',
            justifyContent:'center',
            alignItems: 'center', my: { sm: 4 } }}
            item className="ing-kushki_product" xs={12} md={4} lg={4}  >
              <img src={aceptable} alt="detekta"  />
              <Typography align="center" variant="body2"gutterBottom color="text.dark" sx={{ mt: 5 }}>
                <strong>Sin alertas y/o hallazgos</strong> que adviertan condicionamientos jurídicos graves. <strong>Sin embargo,</strong> se detectaron actos que podrían generar <strong>obstáculos</strong> para la transacción
              </Typography>
          </Grid>
          <Grid item className="ing-kushki_product"
          sx={{ display: 'flex', flexDirection:'column',
          justifyContent:'center',
          alignItems: 'center', my: { sm: 4 } }} xs={12} md={4} lg={4} >
            <img src={alert} alt="portales de recaudo" c />
            <Typography align="center" variant="body2" gutterBottom color="text.dark" sx={{ mt: 5 }}>
              Detectados actos jurídicos de <strong>falsa tradición o hallazgos</strong> que podrían <strong>exponer a riesgos o complicaciones</strong> técnico-legales al usuario.
            </Typography>
          </Grid>
        </Grid>
      </Container>
   </div>
  )
}

export default DetektaTrafficLight