import React, {FC} from 'react';
import { Grid, Typography, Container, Card, CardContent } from '@mui/material';
import warning from './assets/images/warning.svg';
import doc from './assets/images/doc.svg';
import chart from './assets/images/chart.svg';
import certificado from './assets/images/certificadoDetekta.png';
import './styles.scss'

const DetektaBenefits: FC = () => {
  return (
    <Container>
      <Grid container lg={12} md={12} sm={12}
        spacing={{ xs: 2, md: 3 }}
        direction="row"
        justifyContent="center"
        alignItems="center"
        columns={12}
        sx={{ position: 'relative', bottom: 32 }}
        className="grid-benefits-cards-container"
      >
        <Grid item md={4} xs={12} >
          <Card sx={{ minWidth: 198, minHeight: 227, borderBottom: 5, borderBottomColor: '#8F4F8B' , pt: 2}}>
            <CardContent className="ing-kushki_card-content" >
              <img src={warning} alt="pointer" />
              <Typography color="text.dark" variant="caption">
                Hacemos una <strong>calificación de riesgos</strong> de acuerdo al pasado jurídico del inmueble.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={4} xs={12}>
          <Card sx={{ minWidth: 198, minHeight: 227, borderBottom: 5, borderBottomColor: '#8F4F8B', pt: 2}}>
            <CardContent className="ing-kushki_card-content">
              <img src={doc} alt="pointer" />
              <Typography color="text.dark" variant="caption">
                Explicamos en detalle cada uno de los <strong>hallazgos</strong> identificados en el <strong>Certificado de Tradición y Libertad. </strong>
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={4} xs={12}>
          <Card sx={{ minWidth: 198, minHeight: 227, borderBottom: 5, borderBottomColor: '#8F4F8B' , pt: 1.2}}>
            <CardContent className="ing-kushki_card-content">
              <img src={chart} alt="pointer" />
              <Typography color="text.dark" variant="caption">
                Analizamos el <strong>histórico de propietarios</strong> y tradentes y los consultamos en listas restrictivas <strong>SARLAFT (Próximamente).</strong>
              </Typography>
            </CardContent>
          </Card>
        </Grid>        
      </Grid>
      <Grid container lg={12} md={12} sm={12}
        spacing={{ xs: 2, md: 3 }}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item md={6} xs={12} className="detekta-benefits-text-container" direction="column">
          <Grid md={12} sm={12} xs={12}>
            <Typography color="text.dark" variant="h4" className="detekta-benefits-text">
              <strong>Detekta analiza el Certificado de Tradición y Libertad</strong> mediante procesos de <strong>inteligencia artificial,</strong> generando un diagnóstico detallado de la situación jurídica del inmueble.
            </Typography>
          </Grid>
          <Grid md={12} sm={12} xs={12}>
            <Typography color="text.dark" variant="h4" className="detekta-benefits-text">
              <strong>En minutos, Detekta</strong> emite un diagnóstico acerca del historial jurídico de la propiedad e identifica potenciales condiciones que debe tener en cuenta en sus trámites de negocio.
            </Typography>
          </Grid>
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          <img src={certificado} alt="" className="detekta-cert-img"/>
        </Grid>
      </Grid>
    </Container>
  )
}

export default DetektaBenefits