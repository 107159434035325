import React, {FC} from 'react'
import { Grid, Typography, Container} from '@mui/material';
import focus from './assets/images/focus.svg'
import gear from './assets/images/gear.svg'
import timer from './assets/images/timer.svg'
import cert from './assets/images/cert.svg'
import api from './assets/images/api.svg'

const DetektaAdvantage: FC = () => {
  return (
    <Container sx={{ py: 10 }} >
        <Typography variant="h1" color="text.dark" align="center" >
          Ventajas
        </Typography>
        <Grid container sx={{ mt: 5, pl:{md:'none', xs:'1.5rem'} }}
          spacing={2}
          direction="row"
          justifyContent="center"
          alignItems="start"
          columns={{ sm: 10, md: 12 }}>
          
          <Grid item
            sx={{ display: 'flex', flexDirection:'column',
            justifyContent:'center',
            alignItems: 'center', my: { sm: 4 }, px: 3 }}
            xs={12} md={2} lg={2} >
            <img src={focus} alt="ideal" />
            <Typography align="center" variant="body2" gutterBottom color="text.primary" sx={{ mt: 2 }}>
              Descargas <strong>individuales o múltiples</strong>
            </Typography>
          </Grid>
          <Grid sx={{ display: 'flex', flexDirection:'column',
            justifyContent:'center',
            alignItems: 'center', my: { sm: 4 }, px: 3  }}
            item className="ing-kushki_product" xs={12} md={2} lg={2}  >
              <img src={gear} alt="detekta"  />
              <Typography align="center" variant="body2"gutterBottom color="text.primary" sx={{ mt: 2 }}>
                Diagnóstico <strong>automatizado</strong>
              </Typography>
          </Grid>
          <Grid item className="ing-kushki_product"
          sx={{ display: 'flex', flexDirection:'column',
          justifyContent:'center',
          alignItems: 'center', my: { sm: 4 }, px: 3  }} xs={12} md={2} lg={2} >
            <img src={timer} alt="portales de recaudo" />
            <Typography align="center" variant="body2" gutterBottom color="text.primary" sx={{ mt: 2 }}>
              Disminución de tareas operativas <strong>(entrega en segundos)</strong>
            </Typography>
          </Grid>
          <Grid item className="ing-kushki_product"
          sx={{ display: 'flex', flexDirection:'column',
          justifyContent:'center',
          alignItems: 'center', my: { sm: 4 }, px: 3  }} xs={12} md={2} lg={2} >
            <img src={cert} alt="portales de recaudo" />
            <Typography align="center" variant="body2" gutterBottom color="text.primary" sx={{ mt: 2 }}>
              Entrega de datos en <strong>diferentes formatos</strong> para integrar a tus sistemas de gestión
            </Typography>
          </Grid>
          <Grid item className="ing-kushki_product"
          sx={{ display: 'flex', flexDirection:'column',
          justifyContent:'center',
          alignItems: 'center', my: { sm: 4 }, px: 3  }} xs={12} md={2} lg={2} >
            <img src={api} alt="portales de recaudo" />
            <Typography align="center" variant="body2" gutterBottom color="text.primary" sx={{ mt: 2 }}>
              Integración vía <strong>API</strong>
            </Typography>
          </Grid>
        </Grid>
      </Container>
  )
}

export default DetektaAdvantage